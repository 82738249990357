import { Controller } from "@hotwired/stimulus";
import { ClientJS } from "clientjs";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    const fingerprint = new ClientJS().getFingerprint();
    this.buttonTargets.forEach((target) => {
      this.appendFingerprintToState(target, fingerprint);
    });
  }

  appendFingerprintToState(button, fingerprint) {
    const form = button.closest('form');
    if (form) {
      const url = new URL(form.getAttribute("action"), window.location.origin);
      url.searchParams.append("fingerprint", fingerprint);
      form.action = url;
    }
  }
}
