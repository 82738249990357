import { Controller } from "@hotwired/stimulus"

// This is needed, because it can happen that broadcast
// for adapty#check can arrive earlier then page is rendered.
// In such case we must make sure move on.
// Redirect to root makes sure that paywall check will happen again.
export default class extends Controller {

  static values = { userRootPath: String }

  connect() {
    setTimeout(() => {
      window.location = this.userRootPathValue
    }, 3000); // 3000 milliseconds = 3 seconds
  }

}
