import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["nextButton", "radioInput"]

  connect() {
    this.updateButtonState()
  }

  updateButtonState() {
    const hasRadioButtons = this.radioInputTargets.length > 0;
    const isAnyRadioSelected = this.radioInputTargets.some(input => input.checked)
    this.nextButtonTarget.disabled = hasRadioButtons && !isAnyRadioSelected
  }

  radioChanged() {
    this.updateButtonState()
  }
}
