import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { isPremium: Boolean, userRootPath: String, newSubscriptionPath: String }

  connect() {
    window.location = this.isPremiumValue ? this.userRootPathValue : this.newSubscriptionPathValue;
  }
}
