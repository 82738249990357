/* eslint no-console:0 */

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"

document.addEventListener("turbo:load", (event) => {
  let googleAnalyticsId = document.querySelector("meta[name='google-analytics-id']").content
  // we are setting it only on production
  if (googleAnalyticsId) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', googleAnalyticsId);
  }
});